.MuiFormHelperText-root {
  position: absolute;
  width: 100%;
  bottom: -24px;
}

.MuiFormHelperText-root {
  margin-left: 0 !important;
  bottom: -22px;
}

.MuiFormControl-root.checkbox .MuiFormHelperText-root {
  margin-bottom: 6px;
}

.MuiTableContainer-root {
  position: relative;
}

.MuiTable-root .MuiTableRow-head .MuiTableCell-head {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
}

.MuiPagination-root {
  padding: 8px 0;
}

@media only screen and (max-width: 600px) {
  .MuiTableCell-root {
    padding: 6px !important;
  }
}