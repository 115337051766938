.custom-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  opacity: 0.8;
}

.custom-loader-content {
  margin-top: 18px;
}

@media (max-width: 780px) {
  .custom-loader {
    position: fixed;
  }
}

@page {
  size: auto;
  margin: 20mm;
}