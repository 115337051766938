.Toastify__toast-container {
  top: 20px;
}

.Toastify__toast {
  padding: 0;
  min-height: 48px;
  border-radius: 8px;
}

.Toastify__toast-body {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .Toastify__toast-container {
    top: 0
  }

  .Toastify__toast,
  .Toastify__toast .MuiAlert-root {
    border-radius: 0;
  }
}